@mixin nav-label($hover: false, $weight: $weight-semibold) {
  font-family: $family-body;
  font-size: rem(13);
  line-height: $line-height-text;
  font-weight: $weight;
  @if ($hover) {
    @include simple-link;
  }
}

@mixin nav-label-l($hover: false, $weight: $weight-semibold) {
  font-family: $family-body;
  font-size: rem(14);
  line-height: $line-height-text;
  font-weight: $weight;
  @if ($hover) {
    @include simple-link;
  }
}

@mixin nav-label-alt($hover: false) {
  @include nav-label($hover, $weight-normal);
}

@mixin text-label {
  font-family: $family-body;
  font-size: rem(14);
  line-height: $line-height-text;
}

@mixin lead-label {
  font-family: $family-body;
  font-size: rem(20);
  line-height: $line-height-label;
}

@mixin small-label {
  font-family: $family-body;
  font-size: $size-smaller;
  line-height: $line-height-label;
  letter-spacing: 0.1em;
}

@mixin smaller-label {
  font-family: $family-body;
  font-size: $size-smaller;
  line-height: $line-height-label;
  letter-spacing: 0.1em;
}

@mixin smallest-label {
  font-family: $family-body;
  font-size: $size-smallest;
  line-height: $line-height-label;
  letter-spacing: 0.1em;
}

@mixin large-label {
  font-family: $family-body;
  font-size: rem(32);
  line-height: $line-height-label;
  @include mobile {
    font-size: rem(24);
  }
}

@mixin simple-link {
  @include transition-generic(opacity);
  &:hover {
    opacity: .7;
  }
}

@mixin underline-link {
  &:hover {
    text-decoration: underline;
  }
}
