$white: #ffffff;
$black: #010101;
$black-invert: $white;

$yellow: #FFC600;
$pink: #fbc3b4;
$cream-dark: #FCEFCE;
$cream: #f9f4ef;
$blue: #007497;
$blue-dark: #091F40;
$light: #F5F1EE;

$grey: #c1c1c1;
$grey-middle: #535353;
$grey-light: #f6f6f6;
$grey-lightest: #d2d2d2;

$red: #DF402A;
$red-dark: #bd321f;

$error: #af1a1a;


$bg: $yellow;
$bg-dark: #F9F4EF;

// main
$dark: $blue-dark;
$dark-invert: $white;

$primary: $yellow;
$primary-invert: $white;

$secondary: $blue;
$secondary-invert: $white;

// text
$text: $dark;
$text-dark: $dark;
$text-strong: inherit;

// links
$link: $text-dark;
$link-invert: $white;
$link-visited: $link;
$link-hover: rgba($text-dark, 0.75);
$link-focus: $link;
$link-active: $link;

$colors: (
  "dark": ($dark, $dark-invert),
  "black": ($black, $black-invert),
  "primary": ($primary, $primary-invert),
  "secondary": ($secondary, $secondary-invert),
);

// body
$body-background-color: $bg;
