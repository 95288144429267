.button-link {
  @include button-reset;
  display: inline-flex;
  font-family: $family-body;
  font-size: rem(13);
  line-height: 1.05;
  font-weight: $weight-medium;

  &.is-danger,
  &.is-error,
  &.is-red {
    color: $red;
  }

  &.is-full {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
