//::selection {
//  background: $secondary;
//  color: $secondary-invert;
//}
//
//::-moz-selection {
//  background: $secondary;
//  color: $secondary-invert;
//}

body {
  background: $body-background-color;
}

.wrap {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include crapie {
    display: block;
    overflow: hidden;
  }
}

.main {
  flex: 1;
  position: relative;
  z-index: map-get($z-index, bg);

  display: flex;
  align-items: center;
  justify-content: center;
}
