@mixin spacing-side($type: padding, $side: false) {
  $type-side: #{$type};
  @if $side {
    $type-side: #{$type}-#{$side};
  }
  #{$type-side}: $spacing-gap-mobile;
  @include tablet {
    #{$type-side}: $spacing-gap-tablet;
  }
  @include desktop {
    #{$type-side}: $spacing-gap-desktop;
  }
  @include widescreen {
    #{$type-side}: $spacing-gap-widescreen;
  }
  @include fullhd {
    #{$type-side}: $spacing-gap-fullhd;
  }
}

@mixin spacing($type: padding) {
  @include spacing-side($type, left);
  @include spacing-side($type, right);
}

@mixin position-sized($spacing, $side) {
  #{$side}: $spacing;
  @include tablet {
    #{$side}: $spacing * 1.25;
  }
  @include desktop {
    #{$side}: $spacing * 1.5;
  }
  @include widescreen {
    #{$side}: $spacing * 1.75;
  }
  @include fullhd {
    #{$side}: $spacing * 2;
  }
}

@mixin spacing-sized($spacing, $side, $spacing-type: 'margin') {
  #{$spacing-type}-#{$side}: $spacing;
  @include tablet {
    #{$spacing-type}-#{$side}: $spacing * 1.25;
  }
  @include desktop {
    #{$spacing-type}-#{$side}: $spacing * 1.5;
  }
  @include widescreen {
    #{$spacing-type}-#{$side}: $spacing * 1.75;
  }
  @include fullhd {
    #{$spacing-type}-#{$side}: $spacing * 2;
  }
}

@each $side in $sides {
  .has-marg-#{$side}-auto {
    margin-#{$side}: auto;
  }
  .has-marg-#{$side}-none {
    margin-#{$side}: 0 !important;
  }
  .has-marg-#{$side} {
    @include spacing-sized($spacing, $side, margin);

    &-xxs {
      @include spacing-sized($spacing-xxs, $side, margin);
    }

    &-xs {
      @include spacing-sized($spacing-xs, $side, margin);
    }

    &-s,
    &-sm {
      @include spacing-sized($spacing-s, $side, margin);
    }

    &-m,
    &-md {
      @include spacing-sized($spacing-m, $side, margin);
    }

    &-l,
    &-lg {
      @include spacing-sized($spacing-l, $side, margin);
    }

    &-xl {
      @include spacing-sized($spacing-xl, $side, margin);
    }

    &-xxl {
      @include spacing-sized($spacing-xxl, $side, margin);
    }

    &-3xl {
      @include spacing-sized($spacing-3xl, $side, margin);
    }

    &-4xl {
      @include spacing-sized($spacing-4xl, $side, margin);
    }
  }
  .has-pad-#{$side}-none {
    padding-#{$side}: 0 !important;
  }
  .has-pad-#{$side} {
    @include spacing-sized($spacing, $side, padding);

    &-xxs {
      @include spacing-sized($spacing-xxs, $side, padding);
    }

    &-xs {
      @include spacing-sized($spacing-xs, $side, padding);
    }

    &-s,
    &-sm {
      @include spacing-sized($spacing-s, $side, padding);
    }

    &-m,
    &-md {
      @include spacing-sized($spacing-m, $side, padding);
    }

    &-l,
    &-lg {
      @include spacing-sized($spacing-l, $side, padding);
    }

    &-xl {
      @include spacing-sized($spacing-xl, $side, padding);
    }

    &-xxl {
      @include spacing-sized($spacing-xxl, $side, padding);
    }

    &-3xl {
      @include spacing-sized($spacing-3xl, $side, padding);
    }

    &-4xl {
      @include spacing-sized($spacing-4xl, $side, padding);
    }
  }
}
