.button {
  @include button-reset;
  @include button-default;
  @include transition-button;
  min-width: 190px;

  &,
  &.is-outline {
    border-color: $dark;
    color: $dark;

    &:hover {
      color: $white;
      background: $dark;
      border-color: $dark;
    }
  }

  &.is-yellow {
    border-color: $yellow;
    color: $white;
    background: $yellow;

    &:hover {
      color: $white;
      background: lighten($yellow, 3%);
      border-color: lighten($yellow, 3%);
    }
  }

  &.is-pink {
    border-color: $pink;
    color: $white;
    background: $pink;

    &:hover {
      color: $white;
      background: lighten($pink, 2%);
      border-color: lighten($pink, 2%);
    }
  }


  &.is-light {
    border-color: $white;
    color: $white;

    &:hover {
      color: $dark;
      background: $white;
    }
  }

  &.is-dark {
    border-color: $dark;
    color: $white;
    background: $dark;

    &:hover {
      color: $white;
      background: lighten($dark, 10%);
    }
  }

  &.is-black {
    border-color: $black;
    color: $white;
    background: $black;

    &:hover {
      color: $white;
      background: lighten($black, 10%);
    }
  }

  &.is-wide {
    min-width: rem(240);
  }

  &.is-narrow {
    padding-left: 2em;
    padding-right: 2em;
  }

  &.is-full {
    display: flex;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &:disabled,
  &.is-disabled {
    opacity: .7;
    cursor: default;
  }

  &.is-loading {
    cursor: wait;
  }

  &__label + &__icon {
    margin-right: rem(15);
  }

  &__icon + &__label {
    margin-left: rem(15);
  }
}
