@mixin calc-vh($height, $prop: height) {
  /* Fallback for browsers that do not support Custom Properties */
  #{$prop}: #{$height}vh;
  #{$prop}: calc(var(--vh, 1vh) * #{$height});
}

@mixin header-drop {
  @include touch {
    @content;
  }
}

@mixin header-full {
  @include desktop {
    @content;
  }
}


@mixin tiny {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin tiny-up {
  @media screen and (min-width: $mobile-max-width) {
    @content;
  }
}

@mixin mobile-legacy {
  @media screen and (max-width: $mobile-legacy) {
    @content;
  }
}

@mixin mobile-legacy-up {
  @media screen and (min-width: $mobile-max-width) {
    @content;
  }
}
