$max-width-tablet: $tablet + $spacing-gap-tablet + $spacing-gap-tablet;
$max-width-mobile: $mobile + $spacing-gap-mobile + $spacing-gap-mobile;
$max-width-desktop: $desktop + $spacing-gap + $spacing-gap;
$max-width-widescreen: $widescreen + $spacing-gap-widescreen + $spacing-gap-widescreen;
$max-width-fullhd: $fullhd + $spacing-gap-fullhd + $spacing-gap-fullhd;

@mixin container($max: 'widescreen') {
  @include spacing;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @if ($max == 'widescreen') {
    max-width: $max-width-widescreen;
  } @else if ($max == 'desktop') {
    max-width: $max-width-desktop;
  } @else if ($max == 'tablet') {
    max-width: $max-width-tablet;
  } @else if ($max == 'mobile') {
    max-width: $max-width-mobile;
  } @else if($max) {
    max-width: $max;
  } @else {
    max-width: $max-width-fullhd;
  }
}

@mixin container-fixed {
  @include container;
  &.m-desktop {
    max-width: $max-width-desktop;
  }
  &.m-widescreen {
    max-width: $max-width-widescreen;
  }
  &.m-tablet {
    max-width: $max-width-tablet;
  }
  &.m-mobile {
    max-width: $max-width-mobile;
  }
}
