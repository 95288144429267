@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url("graphik-regular.47e94839.woff2") format("woff2");
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 500;
  src: url("graphik-medium.2d7e1421.woff2") format("woff2");
}

@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  src: url("graphik-semibold.a868d32f.woff2") format("woff2");
}

.content:not(:last-child) {
  margin-bottom: 1.5rem;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select, textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

td:not([align]), th:not([align]) {
  text-align: inherit;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: initial;
  overflow-y: initial;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  background-color: #ffc600;
  font-size: 16px;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}

body, button, input, optgroup, select, textarea {
  font-family: Graphik, sans-serif;
}

code, pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #091f40;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.5;
}

a {
  color: #091f40;
  cursor: pointer;
  text-decoration: none;
}

a strong {
  color: currentColor;
}

a:hover {
  color: #091f40bf;
}

code {
  color: #a42a19;
  background-color: #f5f5f5;
  padding: .25em .5em;
  font-size: .875em;
  font-weight: normal;
}

hr {
  height: 2px;
  background-color: #f5f5f5;
  border: none;
  margin: 1.5rem 0;
  display: block;
}

img {
  height: auto;
  max-width: 100%;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: baseline;
}

small {
  font-size: .875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: inherit;
  font-weight: 700;
}

fieldset {
  border: none;
}

pre {
  -webkit-overflow-scrolling: touch;
  color: #091f40;
  white-space: pre;
  word-wrap: normal;
  background-color: #f5f5f5;
  padding: 1.25rem 1.5rem;
  font-size: .875em;
  overflow-x: auto;
}

pre code {
  color: currentColor;
  background-color: #0000;
  padding: 0;
  font-size: 1em;
}

table td, table th {
  vertical-align: top;
}

table td:not([align]), table th:not([align]) {
  text-align: inherit;
}

table th {
  color: inherit;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.column {
  flex: 1 1 0;
  padding: .625rem;
  display: block;
}

.columns.is-mobile > .column.is-narrow {
  width: unset;
  flex: none;
}

.columns.is-mobile > .column.is-full {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-three-quarters {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-two-thirds {
  width: 66.6666%;
  flex: none;
}

.columns.is-mobile > .column.is-half {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-one-third {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-one-quarter {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-one-fifth {
  width: 20%;
  flex: none;
}

.columns.is-mobile > .column.is-two-fifths {
  width: 40%;
  flex: none;
}

.columns.is-mobile > .column.is-three-fifths {
  width: 60%;
  flex: none;
}

.columns.is-mobile > .column.is-four-fifths {
  width: 80%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-three-quarters {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-offset-two-thirds {
  margin-left: 66.6666%;
}

.columns.is-mobile > .column.is-offset-half {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-offset-one-third {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-offset-one-quarter {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-offset-one-fifth {
  margin-left: 20%;
}

.columns.is-mobile > .column.is-offset-two-fifths {
  margin-left: 40%;
}

.columns.is-mobile > .column.is-offset-three-fifths {
  margin-left: 60%;
}

.columns.is-mobile > .column.is-offset-four-fifths {
  margin-left: 80%;
}

.columns.is-mobile > .column.is-0 {
  width: 0%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-0 {
  margin-left: 0%;
}

.columns.is-mobile > .column.is-1 {
  width: 8.33333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-1 {
  margin-left: 8.33333%;
}

.columns.is-mobile > .column.is-2 {
  width: 16.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-2 {
  margin-left: 16.6667%;
}

.columns.is-mobile > .column.is-3 {
  width: 25%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-3 {
  margin-left: 25%;
}

.columns.is-mobile > .column.is-4 {
  width: 33.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-4 {
  margin-left: 33.3333%;
}

.columns.is-mobile > .column.is-5 {
  width: 41.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-5 {
  margin-left: 41.6667%;
}

.columns.is-mobile > .column.is-6 {
  width: 50%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-6 {
  margin-left: 50%;
}

.columns.is-mobile > .column.is-7 {
  width: 58.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-7 {
  margin-left: 58.3333%;
}

.columns.is-mobile > .column.is-8 {
  width: 66.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-8 {
  margin-left: 66.6667%;
}

.columns.is-mobile > .column.is-9 {
  width: 75%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-9 {
  margin-left: 75%;
}

.columns.is-mobile > .column.is-10 {
  width: 83.3333%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-10 {
  margin-left: 83.3333%;
}

.columns.is-mobile > .column.is-11 {
  width: 91.6667%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-11 {
  margin-left: 91.6667%;
}

.columns.is-mobile > .column.is-12 {
  width: 100%;
  flex: none;
}

.columns.is-mobile > .column.is-offset-12 {
  margin-left: 100%;
}

@media screen and (max-width: 767px) {
  .column.is-narrow-mobile {
    width: unset;
    flex: none;
  }

  .column.is-full-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-mobile {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-mobile {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-mobile {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-mobile {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-mobile {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-mobile {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-mobile {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-mobile {
    margin-left: 50%;
  }

  .column.is-offset-one-third-mobile {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-mobile {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-mobile {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-mobile {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-mobile {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-mobile {
    margin-left: 80%;
  }

  .column.is-0-mobile {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-mobile {
    margin-left: 0%;
  }

  .column.is-1-mobile {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-mobile {
    margin-left: 8.33333%;
  }

  .column.is-2-mobile {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-mobile {
    margin-left: 16.6667%;
  }

  .column.is-3-mobile {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-mobile {
    margin-left: 25%;
  }

  .column.is-4-mobile {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-mobile {
    margin-left: 33.3333%;
  }

  .column.is-5-mobile {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-mobile {
    margin-left: 41.6667%;
  }

  .column.is-6-mobile {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-mobile {
    margin-left: 50%;
  }

  .column.is-7-mobile {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-mobile {
    margin-left: 58.3333%;
  }

  .column.is-8-mobile {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-mobile {
    margin-left: 66.6667%;
  }

  .column.is-9-mobile {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-mobile {
    margin-left: 75%;
  }

  .column.is-10-mobile {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-mobile {
    margin-left: 83.3333%;
  }

  .column.is-11-mobile {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-mobile {
    margin-left: 91.6667%;
  }

  .column.is-12-mobile {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-mobile {
    margin-left: 100%;
  }
}

@media screen and (min-width: 768px), print {
  .column.is-narrow, .column.is-narrow-tablet {
    width: unset;
    flex: none;
  }

  .column.is-full, .column.is-full-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters, .column.is-three-quarters-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds, .column.is-two-thirds-tablet {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half, .column.is-half-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-one-third, .column.is-one-third-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter, .column.is-one-quarter-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth, .column.is-one-fifth-tablet {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths, .column.is-two-fifths-tablet {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths, .column.is-three-fifths-tablet {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths, .column.is-four-fifths-tablet {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters, .column.is-offset-three-quarters-tablet {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds, .column.is-offset-two-thirds-tablet {
    margin-left: 66.6666%;
  }

  .column.is-offset-half, .column.is-offset-half-tablet {
    margin-left: 50%;
  }

  .column.is-offset-one-third, .column.is-offset-one-third-tablet {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter, .column.is-offset-one-quarter-tablet {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth, .column.is-offset-one-fifth-tablet {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths, .column.is-offset-two-fifths-tablet {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths, .column.is-offset-three-fifths-tablet {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths, .column.is-offset-four-fifths-tablet {
    margin-left: 80%;
  }

  .column.is-0, .column.is-0-tablet {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0, .column.is-offset-0-tablet {
    margin-left: 0%;
  }

  .column.is-1, .column.is-1-tablet {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1, .column.is-offset-1-tablet {
    margin-left: 8.33333%;
  }

  .column.is-2, .column.is-2-tablet {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2, .column.is-offset-2-tablet {
    margin-left: 16.6667%;
  }

  .column.is-3, .column.is-3-tablet {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3, .column.is-offset-3-tablet {
    margin-left: 25%;
  }

  .column.is-4, .column.is-4-tablet {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4, .column.is-offset-4-tablet {
    margin-left: 33.3333%;
  }

  .column.is-5, .column.is-5-tablet {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5, .column.is-offset-5-tablet {
    margin-left: 41.6667%;
  }

  .column.is-6, .column.is-6-tablet {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6, .column.is-offset-6-tablet {
    margin-left: 50%;
  }

  .column.is-7, .column.is-7-tablet {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7, .column.is-offset-7-tablet {
    margin-left: 58.3333%;
  }

  .column.is-8, .column.is-8-tablet {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8, .column.is-offset-8-tablet {
    margin-left: 66.6667%;
  }

  .column.is-9, .column.is-9-tablet {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9, .column.is-offset-9-tablet {
    margin-left: 75%;
  }

  .column.is-10, .column.is-10-tablet {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10, .column.is-offset-10-tablet {
    margin-left: 83.3333%;
  }

  .column.is-11, .column.is-11-tablet {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11, .column.is-offset-11-tablet {
    margin-left: 91.6667%;
  }

  .column.is-12, .column.is-12-tablet {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12, .column.is-offset-12-tablet {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1059px) {
  .column.is-narrow-touch {
    width: unset;
    flex: none;
  }

  .column.is-full-touch {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-touch {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-touch {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-touch {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-touch {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-touch {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-touch {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-touch {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-touch {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-touch {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-touch {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-touch {
    margin-left: 50%;
  }

  .column.is-offset-one-third-touch {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-touch {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-touch {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-touch {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-touch {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-touch {
    margin-left: 80%;
  }

  .column.is-0-touch {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-touch {
    margin-left: 0%;
  }

  .column.is-1-touch {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-touch {
    margin-left: 8.33333%;
  }

  .column.is-2-touch {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-touch {
    margin-left: 16.6667%;
  }

  .column.is-3-touch {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-touch {
    margin-left: 25%;
  }

  .column.is-4-touch {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-touch {
    margin-left: 33.3333%;
  }

  .column.is-5-touch {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-touch {
    margin-left: 41.6667%;
  }

  .column.is-6-touch {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-touch {
    margin-left: 50%;
  }

  .column.is-7-touch {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-touch {
    margin-left: 58.3333%;
  }

  .column.is-8-touch {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-touch {
    margin-left: 66.6667%;
  }

  .column.is-9-touch {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-touch {
    margin-left: 75%;
  }

  .column.is-10-touch {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-touch {
    margin-left: 83.3333%;
  }

  .column.is-11-touch {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-touch {
    margin-left: 91.6667%;
  }

  .column.is-12-touch {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-touch {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1060px) {
  .column.is-narrow-desktop {
    width: unset;
    flex: none;
  }

  .column.is-full-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-desktop {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-desktop {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-desktop {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-desktop {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-desktop {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-desktop {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-desktop {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-desktop {
    margin-left: 50%;
  }

  .column.is-offset-one-third-desktop {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-desktop {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-desktop {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-desktop {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-desktop {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-desktop {
    margin-left: 80%;
  }

  .column.is-0-desktop {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-desktop {
    margin-left: 0%;
  }

  .column.is-1-desktop {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-desktop {
    margin-left: 8.33333%;
  }

  .column.is-2-desktop {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-desktop {
    margin-left: 16.6667%;
  }

  .column.is-3-desktop {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-desktop {
    margin-left: 25%;
  }

  .column.is-4-desktop {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-desktop {
    margin-left: 33.3333%;
  }

  .column.is-5-desktop {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-desktop {
    margin-left: 41.6667%;
  }

  .column.is-6-desktop {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-desktop {
    margin-left: 50%;
  }

  .column.is-7-desktop {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-desktop {
    margin-left: 58.3333%;
  }

  .column.is-8-desktop {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-desktop {
    margin-left: 66.6667%;
  }

  .column.is-9-desktop {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-desktop {
    margin-left: 75%;
  }

  .column.is-10-desktop {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-desktop {
    margin-left: 83.3333%;
  }

  .column.is-11-desktop {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-desktop {
    margin-left: 91.6667%;
  }

  .column.is-12-desktop {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-desktop {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .column.is-narrow-widescreen {
    width: unset;
    flex: none;
  }

  .column.is-full-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-widescreen {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-widescreen {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-widescreen {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-widescreen {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-widescreen {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-widescreen {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-widescreen {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-widescreen {
    margin-left: 50%;
  }

  .column.is-offset-one-third-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-widescreen {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-widescreen {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-widescreen {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-widescreen {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-widescreen {
    margin-left: 80%;
  }

  .column.is-0-widescreen {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-widescreen {
    margin-left: 0%;
  }

  .column.is-1-widescreen {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-widescreen {
    margin-left: 8.33333%;
  }

  .column.is-2-widescreen {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-widescreen {
    margin-left: 16.6667%;
  }

  .column.is-3-widescreen {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-widescreen {
    margin-left: 25%;
  }

  .column.is-4-widescreen {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-widescreen {
    margin-left: 33.3333%;
  }

  .column.is-5-widescreen {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-widescreen {
    margin-left: 41.6667%;
  }

  .column.is-6-widescreen {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-widescreen {
    margin-left: 50%;
  }

  .column.is-7-widescreen {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-widescreen {
    margin-left: 58.3333%;
  }

  .column.is-8-widescreen {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-widescreen {
    margin-left: 66.6667%;
  }

  .column.is-9-widescreen {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-widescreen {
    margin-left: 75%;
  }

  .column.is-10-widescreen {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-widescreen {
    margin-left: 83.3333%;
  }

  .column.is-11-widescreen {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-widescreen {
    margin-left: 91.6667%;
  }

  .column.is-12-widescreen {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-widescreen {
    margin-left: 100%;
  }
}

@media screen and (min-width: 1460px) {
  .column.is-narrow-fullhd {
    width: unset;
    flex: none;
  }

  .column.is-full-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-three-quarters-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-two-thirds-fullhd {
    width: 66.6666%;
    flex: none;
  }

  .column.is-half-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-one-third-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-one-quarter-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-one-fifth-fullhd {
    width: 20%;
    flex: none;
  }

  .column.is-two-fifths-fullhd {
    width: 40%;
    flex: none;
  }

  .column.is-three-fifths-fullhd {
    width: 60%;
    flex: none;
  }

  .column.is-four-fifths-fullhd {
    width: 80%;
    flex: none;
  }

  .column.is-offset-three-quarters-fullhd {
    margin-left: 75%;
  }

  .column.is-offset-two-thirds-fullhd {
    margin-left: 66.6666%;
  }

  .column.is-offset-half-fullhd {
    margin-left: 50%;
  }

  .column.is-offset-one-third-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-offset-one-quarter-fullhd {
    margin-left: 25%;
  }

  .column.is-offset-one-fifth-fullhd {
    margin-left: 20%;
  }

  .column.is-offset-two-fifths-fullhd {
    margin-left: 40%;
  }

  .column.is-offset-three-fifths-fullhd {
    margin-left: 60%;
  }

  .column.is-offset-four-fifths-fullhd {
    margin-left: 80%;
  }

  .column.is-0-fullhd {
    width: 0%;
    flex: none;
  }

  .column.is-offset-0-fullhd {
    margin-left: 0%;
  }

  .column.is-1-fullhd {
    width: 8.33333%;
    flex: none;
  }

  .column.is-offset-1-fullhd {
    margin-left: 8.33333%;
  }

  .column.is-2-fullhd {
    width: 16.6667%;
    flex: none;
  }

  .column.is-offset-2-fullhd {
    margin-left: 16.6667%;
  }

  .column.is-3-fullhd {
    width: 25%;
    flex: none;
  }

  .column.is-offset-3-fullhd {
    margin-left: 25%;
  }

  .column.is-4-fullhd {
    width: 33.3333%;
    flex: none;
  }

  .column.is-offset-4-fullhd {
    margin-left: 33.3333%;
  }

  .column.is-5-fullhd {
    width: 41.6667%;
    flex: none;
  }

  .column.is-offset-5-fullhd {
    margin-left: 41.6667%;
  }

  .column.is-6-fullhd {
    width: 50%;
    flex: none;
  }

  .column.is-offset-6-fullhd {
    margin-left: 50%;
  }

  .column.is-7-fullhd {
    width: 58.3333%;
    flex: none;
  }

  .column.is-offset-7-fullhd {
    margin-left: 58.3333%;
  }

  .column.is-8-fullhd {
    width: 66.6667%;
    flex: none;
  }

  .column.is-offset-8-fullhd {
    margin-left: 66.6667%;
  }

  .column.is-9-fullhd {
    width: 75%;
    flex: none;
  }

  .column.is-offset-9-fullhd {
    margin-left: 75%;
  }

  .column.is-10-fullhd {
    width: 83.3333%;
    flex: none;
  }

  .column.is-offset-10-fullhd {
    margin-left: 83.3333%;
  }

  .column.is-11-fullhd {
    width: 91.6667%;
    flex: none;
  }

  .column.is-offset-11-fullhd {
    margin-left: 91.6667%;
  }

  .column.is-12-fullhd {
    width: 100%;
    flex: none;
  }

  .column.is-offset-12-fullhd {
    margin-left: 100%;
  }
}

.columns {
  margin-top: -.625rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.columns:last-child {
  margin-bottom: -.625rem;
}

.columns:not(:last-child) {
  margin-bottom: .875rem;
}

.columns.is-centered {
  justify-content: center;
}

.columns.is-gapless {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns.is-gapless:not(:last-child) {
  margin-bottom: 1.5rem;
}

.columns.is-gapless:last-child {
  margin-bottom: 0;
}

.columns.is-mobile {
  display: flex;
}

.columns.is-multiline {
  flex-wrap: wrap;
}

.columns.is-vcentered {
  align-items: center;
}

@media screen and (min-width: 768px), print {
  .columns:not(.is-desktop) {
    display: flex;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-desktop {
    display: flex;
  }
}

.columns.is-variable {
  --columnGap: .75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap));
}

.columns.is-variable > .column {
  padding-left: var(--columnGap);
  padding-right: var(--columnGap);
}

.columns.is-variable.is-0 {
  --columnGap: 0rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-0-mobile {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-0-tablet {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-0-tablet-only {
    --columnGap: 0rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-0-touch {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-0-desktop {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-0-desktop-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-0-widescreen {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-0-widescreen-only {
    --columnGap: 0rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-0-fullhd {
    --columnGap: 0rem;
  }
}

.columns.is-variable.is-1 {
  --columnGap: .25rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-1-mobile {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-1-tablet {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-1-tablet-only {
    --columnGap: .25rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-1-touch {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-1-desktop {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-1-desktop-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-1-widescreen {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-1-widescreen-only {
    --columnGap: .25rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-1-fullhd {
    --columnGap: .25rem;
  }
}

.columns.is-variable.is-2 {
  --columnGap: .5rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-2-mobile {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-2-tablet {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-2-tablet-only {
    --columnGap: .5rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-2-touch {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-2-desktop {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-2-desktop-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-2-widescreen {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-2-widescreen-only {
    --columnGap: .5rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-2-fullhd {
    --columnGap: .5rem;
  }
}

.columns.is-variable.is-3 {
  --columnGap: .75rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-3-mobile {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-3-tablet {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-3-tablet-only {
    --columnGap: .75rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-3-touch {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-3-desktop {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-3-desktop-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-3-widescreen {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-3-widescreen-only {
    --columnGap: .75rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-3-fullhd {
    --columnGap: .75rem;
  }
}

.columns.is-variable.is-4 {
  --columnGap: 1rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-4-mobile {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-4-tablet {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-4-tablet-only {
    --columnGap: 1rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-4-touch {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-4-desktop {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-4-desktop-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-4-widescreen {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-4-widescreen-only {
    --columnGap: 1rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-4-fullhd {
    --columnGap: 1rem;
  }
}

.columns.is-variable.is-5 {
  --columnGap: 1.25rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-5-mobile {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-5-tablet {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-5-tablet-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-5-touch {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-5-desktop {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-5-desktop-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-5-widescreen {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-5-widescreen-only {
    --columnGap: 1.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-5-fullhd {
    --columnGap: 1.25rem;
  }
}

.columns.is-variable.is-6 {
  --columnGap: 1.5rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-6-mobile {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-6-tablet {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-6-tablet-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-6-touch {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-6-desktop {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-6-desktop-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-6-widescreen {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-6-widescreen-only {
    --columnGap: 1.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-6-fullhd {
    --columnGap: 1.5rem;
  }
}

.columns.is-variable.is-7 {
  --columnGap: 1.75rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-7-mobile {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-7-tablet {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-7-tablet-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-7-touch {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-7-desktop {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-7-desktop-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-7-widescreen {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-7-widescreen-only {
    --columnGap: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-7-fullhd {
    --columnGap: 1.75rem;
  }
}

.columns.is-variable.is-8 {
  --columnGap: 2rem;
}

@media screen and (max-width: 767px) {
  .columns.is-variable.is-8-mobile {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 768px), print {
  .columns.is-variable.is-8-tablet {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .columns.is-variable.is-8-tablet-only {
    --columnGap: 2rem;
  }
}

@media screen and (max-width: 1059px) {
  .columns.is-variable.is-8-touch {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1060px) {
  .columns.is-variable.is-8-desktop {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .columns.is-variable.is-8-desktop-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .columns.is-variable.is-8-widescreen {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .columns.is-variable.is-8-widescreen-only {
    --columnGap: 2rem;
  }
}

@media screen and (min-width: 1460px) {
  .columns.is-variable.is-8-fullhd {
    --columnGap: 2rem;
  }
}

.tile {
  min-height: min-content;
  flex: 1 1 0;
  align-items: stretch;
  display: block;
}

.tile.is-ancestor {
  margin-top: -.75rem;
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.tile.is-ancestor:last-child {
  margin-bottom: -.75rem;
}

.tile.is-ancestor:not(:last-child) {
  margin-bottom: .75rem;
}

.tile.is-child {
  margin: 0 !important;
}

.tile.is-parent {
  padding: .75rem;
}

.tile.is-vertical {
  flex-direction: column;
}

.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 1.5rem !important;
}

@media screen and (min-width: 768px), print {
  .tile:not(.is-child) {
    display: flex;
  }

  .tile.is-1 {
    width: 8.33333%;
    flex: none;
  }

  .tile.is-2 {
    width: 16.6667%;
    flex: none;
  }

  .tile.is-3 {
    width: 25%;
    flex: none;
  }

  .tile.is-4 {
    width: 33.3333%;
    flex: none;
  }

  .tile.is-5 {
    width: 41.6667%;
    flex: none;
  }

  .tile.is-6 {
    width: 50%;
    flex: none;
  }

  .tile.is-7 {
    width: 58.3333%;
    flex: none;
  }

  .tile.is-8 {
    width: 66.6667%;
    flex: none;
  }

  .tile.is-9 {
    width: 75%;
    flex: none;
  }

  .tile.is-10 {
    width: 83.3333%;
    flex: none;
  }

  .tile.is-11 {
    width: 91.6667%;
    flex: none;
  }

  .tile.is-12 {
    width: 100%;
    flex: none;
  }
}

.is-size-1 {
  font-size: 2.25rem !important;
}

.is-size-2 {
  font-size: 2rem !important;
}

.is-size-3 {
  font-size: 1.5rem !important;
}

.is-size-4 {
  font-size: 1.375rem !important;
}

.is-size-5 {
  font-size: 1.125rem !important;
}

.is-size-6 {
  font-size: 1rem !important;
}

.is-size-7 {
  font-size: .875rem !important;
}

.is-size-8 {
  font-size: .8125rem !important;
}

.is-size-9 {
  font-size: .75rem !important;
}

.is-size-10 {
  font-size: .6875rem !important;
}

@media screen and (max-width: 767px) {
  .is-size-1-mobile {
    font-size: 2.25rem !important;
  }

  .is-size-2-mobile {
    font-size: 2rem !important;
  }

  .is-size-3-mobile {
    font-size: 1.5rem !important;
  }

  .is-size-4-mobile {
    font-size: 1.375rem !important;
  }

  .is-size-5-mobile {
    font-size: 1.125rem !important;
  }

  .is-size-6-mobile {
    font-size: 1rem !important;
  }

  .is-size-7-mobile {
    font-size: .875rem !important;
  }

  .is-size-8-mobile {
    font-size: .8125rem !important;
  }

  .is-size-9-mobile {
    font-size: .75rem !important;
  }

  .is-size-10-mobile {
    font-size: .6875rem !important;
  }
}

@media screen and (min-width: 768px), print {
  .is-size-1-tablet {
    font-size: 2.25rem !important;
  }

  .is-size-2-tablet {
    font-size: 2rem !important;
  }

  .is-size-3-tablet {
    font-size: 1.5rem !important;
  }

  .is-size-4-tablet {
    font-size: 1.375rem !important;
  }

  .is-size-5-tablet {
    font-size: 1.125rem !important;
  }

  .is-size-6-tablet {
    font-size: 1rem !important;
  }

  .is-size-7-tablet {
    font-size: .875rem !important;
  }

  .is-size-8-tablet {
    font-size: .8125rem !important;
  }

  .is-size-9-tablet {
    font-size: .75rem !important;
  }

  .is-size-10-tablet {
    font-size: .6875rem !important;
  }
}

@media screen and (max-width: 1059px) {
  .is-size-1-touch {
    font-size: 2.25rem !important;
  }

  .is-size-2-touch {
    font-size: 2rem !important;
  }

  .is-size-3-touch {
    font-size: 1.5rem !important;
  }

  .is-size-4-touch {
    font-size: 1.375rem !important;
  }

  .is-size-5-touch {
    font-size: 1.125rem !important;
  }

  .is-size-6-touch {
    font-size: 1rem !important;
  }

  .is-size-7-touch {
    font-size: .875rem !important;
  }

  .is-size-8-touch {
    font-size: .8125rem !important;
  }

  .is-size-9-touch {
    font-size: .75rem !important;
  }

  .is-size-10-touch {
    font-size: .6875rem !important;
  }
}

@media screen and (min-width: 1060px) {
  .is-size-1-desktop {
    font-size: 2.25rem !important;
  }

  .is-size-2-desktop {
    font-size: 2rem !important;
  }

  .is-size-3-desktop {
    font-size: 1.5rem !important;
  }

  .is-size-4-desktop {
    font-size: 1.375rem !important;
  }

  .is-size-5-desktop {
    font-size: 1.125rem !important;
  }

  .is-size-6-desktop {
    font-size: 1rem !important;
  }

  .is-size-7-desktop {
    font-size: .875rem !important;
  }

  .is-size-8-desktop {
    font-size: .8125rem !important;
  }

  .is-size-9-desktop {
    font-size: .75rem !important;
  }

  .is-size-10-desktop {
    font-size: .6875rem !important;
  }
}

@media screen and (min-width: 1200px) {
  .is-size-1-widescreen {
    font-size: 2.25rem !important;
  }

  .is-size-2-widescreen {
    font-size: 2rem !important;
  }

  .is-size-3-widescreen {
    font-size: 1.5rem !important;
  }

  .is-size-4-widescreen {
    font-size: 1.375rem !important;
  }

  .is-size-5-widescreen {
    font-size: 1.125rem !important;
  }

  .is-size-6-widescreen {
    font-size: 1rem !important;
  }

  .is-size-7-widescreen {
    font-size: .875rem !important;
  }

  .is-size-8-widescreen {
    font-size: .8125rem !important;
  }

  .is-size-9-widescreen {
    font-size: .75rem !important;
  }

  .is-size-10-widescreen {
    font-size: .6875rem !important;
  }
}

@media screen and (min-width: 1460px) {
  .is-size-1-fullhd {
    font-size: 2.25rem !important;
  }

  .is-size-2-fullhd {
    font-size: 2rem !important;
  }

  .is-size-3-fullhd {
    font-size: 1.5rem !important;
  }

  .is-size-4-fullhd {
    font-size: 1.375rem !important;
  }

  .is-size-5-fullhd {
    font-size: 1.125rem !important;
  }

  .is-size-6-fullhd {
    font-size: 1rem !important;
  }

  .is-size-7-fullhd {
    font-size: .875rem !important;
  }

  .is-size-8-fullhd {
    font-size: .8125rem !important;
  }

  .is-size-9-fullhd {
    font-size: .75rem !important;
  }

  .is-size-10-fullhd {
    font-size: .6875rem !important;
  }
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: left !important;
}

.has-text-right {
  text-align: right !important;
}

@media screen and (max-width: 767px) {
  .has-text-centered-mobile {
    text-align: center !important;
  }
}

@media screen and (min-width: 768px), print {
  .has-text-centered-tablet {
    text-align: center !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .has-text-centered-tablet-only {
    text-align: center !important;
  }
}

@media screen and (max-width: 1059px) {
  .has-text-centered-touch {
    text-align: center !important;
  }
}

@media screen and (min-width: 1060px) {
  .has-text-centered-desktop {
    text-align: center !important;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .has-text-centered-desktop-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1200px) {
  .has-text-centered-widescreen {
    text-align: center !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .has-text-centered-widescreen-only {
    text-align: center !important;
  }
}

@media screen and (min-width: 1460px) {
  .has-text-centered-fullhd {
    text-align: center !important;
  }
}

@media screen and (max-width: 767px) {
  .has-text-justified-mobile {
    text-align: justify !important;
  }
}

@media screen and (min-width: 768px), print {
  .has-text-justified-tablet {
    text-align: justify !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .has-text-justified-tablet-only {
    text-align: justify !important;
  }
}

@media screen and (max-width: 1059px) {
  .has-text-justified-touch {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1060px) {
  .has-text-justified-desktop {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .has-text-justified-desktop-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1200px) {
  .has-text-justified-widescreen {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .has-text-justified-widescreen-only {
    text-align: justify !important;
  }
}

@media screen and (min-width: 1460px) {
  .has-text-justified-fullhd {
    text-align: justify !important;
  }
}

@media screen and (max-width: 767px) {
  .has-text-left-mobile {
    text-align: left !important;
  }
}

@media screen and (min-width: 768px), print {
  .has-text-left-tablet {
    text-align: left !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .has-text-left-tablet-only {
    text-align: left !important;
  }
}

@media screen and (max-width: 1059px) {
  .has-text-left-touch {
    text-align: left !important;
  }
}

@media screen and (min-width: 1060px) {
  .has-text-left-desktop {
    text-align: left !important;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .has-text-left-desktop-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1200px) {
  .has-text-left-widescreen {
    text-align: left !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .has-text-left-widescreen-only {
    text-align: left !important;
  }
}

@media screen and (min-width: 1460px) {
  .has-text-left-fullhd {
    text-align: left !important;
  }
}

@media screen and (max-width: 767px) {
  .has-text-right-mobile {
    text-align: right !important;
  }
}

@media screen and (min-width: 768px), print {
  .has-text-right-tablet {
    text-align: right !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1059px) {
  .has-text-right-tablet-only {
    text-align: right !important;
  }
}

@media screen and (max-width: 1059px) {
  .has-text-right-touch {
    text-align: right !important;
  }
}

@media screen and (min-width: 1060px) {
  .has-text-right-desktop {
    text-align: right !important;
  }
}

@media screen and (min-width: 1060px) and (max-width: 1199px) {
  .has-text-right-desktop-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1200px) {
  .has-text-right-widescreen {
    text-align: right !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1459px) {
  .has-text-right-widescreen-only {
    text-align: right !important;
  }
}

@media screen and (min-width: 1460px) {
  .has-text-right-fullhd {
    text-align: right !important;
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.is-italic {
  font-style: italic !important;
}

.is-underlined {
  text-decoration: underline !important;
}

.has-text-weight-light {
  font-weight: 300 !important;
}

.has-text-weight-normal {
  font-weight: 400 !important;
}

.has-text-weight-medium {
  font-weight: 500 !important;
}

.has-text-weight-semibold {
  font-weight: 600 !important;
}

.has-text-weight-bold {
  font-weight: 700 !important;
}

.is-family-primary, .is-family-secondary, .is-family-sans-serif {
  font-family: Graphik, sans-serif !important;
}

.is-family-monospace, .is-family-code {
  font-family: monospace !important;
}

.content li + li {
  margin-top: .25em;
}

.content p:not(:last-child), .content dl:not(:last-child), .content ol:not(:last-child), .content ul:not(:last-child), .content blockquote:not(:last-child), .content pre:not(:last-child), .content table:not(:last-child) {
  margin-bottom: 1em;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
  color: inherit;
  font-weight: 600;
  line-height: 1.125;
}

.content h1 {
  margin-bottom: .5em;
  font-size: 2em;
}

.content h1:not(:first-child) {
  margin-top: 1em;
}

.content h2 {
  margin-bottom: .5714em;
  font-size: 1.75em;
}

.content h2:not(:first-child) {
  margin-top: 1.1428em;
}

.content h3 {
  margin-bottom: .6666em;
  font-size: 1.5em;
}

.content h3:not(:first-child) {
  margin-top: 1.3333em;
}

.content h4 {
  margin-bottom: .8em;
  font-size: 1.25em;
}

.content h5 {
  margin-bottom: .8888em;
  font-size: 1.125em;
}

.content h6 {
  margin-bottom: 1em;
  font-size: 1em;
}

.content blockquote {
  background-color: #f5f5f5;
  border-left: 5px solid #dbdbdb;
  padding: 1.25em 1.5em;
}

.content ol {
  margin-top: 1em;
  margin-left: 2em;
  list-style-position: outside;
}

.content ol:not([type]) {
  list-style-type: decimal;
}

.content ol:not([type]).is-lower-alpha {
  list-style-type: lower-alpha;
}

.content ol:not([type]).is-lower-roman {
  list-style-type: lower-roman;
}

.content ol:not([type]).is-upper-alpha {
  list-style-type: upper-alpha;
}

.content ol:not([type]).is-upper-roman {
  list-style-type: upper-roman;
}

.content ul {
  margin-top: 1em;
  margin-left: 2em;
  list-style: disc;
}

.content ul ul {
  margin-top: .5em;
  list-style-type: circle;
}

.content ul ul ul {
  list-style-type: square;
}

.content dd {
  margin-left: 2em;
}

.content figure {
  text-align: center;
  margin-left: 2em;
  margin-right: 2em;
}

.content figure:not(:first-child) {
  margin-top: 2em;
}

.content figure:not(:last-child) {
  margin-bottom: 2em;
}

.content figure img {
  display: inline-block;
}

.content figure figcaption {
  font-style: italic;
}

.content pre {
  -webkit-overflow-scrolling: touch;
  white-space: pre;
  word-wrap: normal;
  padding: 1.25em 1.5em;
  overflow-x: auto;
}

.content sup, .content sub {
  font-size: 75%;
}

.content table {
  width: 100%;
}

.content table td, .content table th {
  vertical-align: top;
  border: 0 solid #dbdbdb;
  border-bottom-width: 1px;
  padding: .5em .75em;
}

.content table th {
  color: inherit;
}

.content table th:not([align]) {
  text-align: inherit;
}

.content table thead td, .content table thead th {
  color: inherit;
  border-width: 0 0 2px;
}

.content table tfoot td, .content table tfoot th {
  color: inherit;
  border-width: 2px 0 0;
}

.content table tbody tr:last-child td, .content table tbody tr:last-child th {
  border-bottom-width: 0;
}

.content .tabs li + li {
  margin-top: 0;
}

.content.is-small {
  font-size: .8125rem;
}

.content.is-normal {
  font-size: .875rem;
}

.content.is-medium {
  font-size: 1rem;
}

.content.is-large {
  font-size: 1.375rem;
}

.has-marg-top-auto {
  margin-top: auto;
}

.has-marg-top-none {
  margin-top: 0 !important;
}

.has-marg-top {
  margin-top: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top {
    margin-top: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top {
    margin-top: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top {
    margin-top: 2rem;
  }
}

.has-marg-top-xxs {
  margin-top: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-xxs {
    margin-top: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-xxs {
    margin-top: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-xxs {
    margin-top: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-xxs {
    margin-top: .5rem;
  }
}

.has-marg-top-xs {
  margin-top: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-xs {
    margin-top: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-xs {
    margin-top: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-xs {
    margin-top: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-xs {
    margin-top: 1rem;
  }
}

.has-marg-top-s, .has-marg-top-sm {
  margin-top: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-s, .has-marg-top-sm {
    margin-top: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-s, .has-marg-top-sm {
    margin-top: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-s, .has-marg-top-sm {
    margin-top: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-s, .has-marg-top-sm {
    margin-top: 1.5rem;
  }
}

.has-marg-top-m, .has-marg-top-md {
  margin-top: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-m, .has-marg-top-md {
    margin-top: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-m, .has-marg-top-md {
    margin-top: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-m, .has-marg-top-md {
    margin-top: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-m, .has-marg-top-md {
    margin-top: 2rem;
  }
}

.has-marg-top-l, .has-marg-top-lg {
  margin-top: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-l, .has-marg-top-lg {
    margin-top: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-l, .has-marg-top-lg {
    margin-top: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-l, .has-marg-top-lg {
    margin-top: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-l, .has-marg-top-lg {
    margin-top: 3rem;
  }
}

.has-marg-top-xl {
  margin-top: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-xl {
    margin-top: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-xl {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-xl {
    margin-top: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-xl {
    margin-top: 4rem;
  }
}

.has-marg-top-xxl {
  margin-top: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-xxl {
    margin-top: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-xxl {
    margin-top: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-xxl {
    margin-top: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-xxl {
    margin-top: 6rem;
  }
}

.has-marg-top-3xl {
  margin-top: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-3xl {
    margin-top: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-3xl {
    margin-top: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-3xl {
    margin-top: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-3xl {
    margin-top: 8rem;
  }
}

.has-marg-top-4xl {
  margin-top: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-top-4xl {
    margin-top: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-top-4xl {
    margin-top: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-top-4xl {
    margin-top: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-top-4xl {
    margin-top: 12rem;
  }
}

.has-pad-top-none {
  padding-top: 0 !important;
}

.has-pad-top {
  padding-top: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top {
    padding-top: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top {
    padding-top: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top {
    padding-top: 2rem;
  }
}

.has-pad-top-xxs {
  padding-top: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-xxs {
    padding-top: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-xxs {
    padding-top: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-xxs {
    padding-top: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-xxs {
    padding-top: .5rem;
  }
}

.has-pad-top-xs {
  padding-top: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-xs {
    padding-top: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-xs {
    padding-top: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-xs {
    padding-top: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-xs {
    padding-top: 1rem;
  }
}

.has-pad-top-s, .has-pad-top-sm {
  padding-top: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-s, .has-pad-top-sm {
    padding-top: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-s, .has-pad-top-sm {
    padding-top: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-s, .has-pad-top-sm {
    padding-top: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-s, .has-pad-top-sm {
    padding-top: 1.5rem;
  }
}

.has-pad-top-m, .has-pad-top-md {
  padding-top: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-m, .has-pad-top-md {
    padding-top: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-m, .has-pad-top-md {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-m, .has-pad-top-md {
    padding-top: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-m, .has-pad-top-md {
    padding-top: 2rem;
  }
}

.has-pad-top-l, .has-pad-top-lg {
  padding-top: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-l, .has-pad-top-lg {
    padding-top: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-l, .has-pad-top-lg {
    padding-top: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-l, .has-pad-top-lg {
    padding-top: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-l, .has-pad-top-lg {
    padding-top: 3rem;
  }
}

.has-pad-top-xl {
  padding-top: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-xl {
    padding-top: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-xl {
    padding-top: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-xl {
    padding-top: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-xl {
    padding-top: 4rem;
  }
}

.has-pad-top-xxl {
  padding-top: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-xxl {
    padding-top: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-xxl {
    padding-top: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-xxl {
    padding-top: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-xxl {
    padding-top: 6rem;
  }
}

.has-pad-top-3xl {
  padding-top: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-3xl {
    padding-top: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-3xl {
    padding-top: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-3xl {
    padding-top: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-3xl {
    padding-top: 8rem;
  }
}

.has-pad-top-4xl {
  padding-top: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-top-4xl {
    padding-top: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-top-4xl {
    padding-top: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-top-4xl {
    padding-top: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-top-4xl {
    padding-top: 12rem;
  }
}

.has-marg-bottom-auto {
  margin-bottom: auto;
}

.has-marg-bottom-none {
  margin-bottom: 0 !important;
}

.has-marg-bottom {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom {
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom {
    margin-bottom: 2rem;
  }
}

.has-marg-bottom-xxs {
  margin-bottom: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-xxs {
    margin-bottom: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-xxs {
    margin-bottom: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-xxs {
    margin-bottom: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-xxs {
    margin-bottom: .5rem;
  }
}

.has-marg-bottom-xs {
  margin-bottom: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-xs {
    margin-bottom: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-xs {
    margin-bottom: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-xs {
    margin-bottom: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-xs {
    margin-bottom: 1rem;
  }
}

.has-marg-bottom-s, .has-marg-bottom-sm {
  margin-bottom: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-s, .has-marg-bottom-sm {
    margin-bottom: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-s, .has-marg-bottom-sm {
    margin-bottom: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-s, .has-marg-bottom-sm {
    margin-bottom: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-s, .has-marg-bottom-sm {
    margin-bottom: 1.5rem;
  }
}

.has-marg-bottom-m, .has-marg-bottom-md {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-m, .has-marg-bottom-md {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-m, .has-marg-bottom-md {
    margin-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-m, .has-marg-bottom-md {
    margin-bottom: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-m, .has-marg-bottom-md {
    margin-bottom: 2rem;
  }
}

.has-marg-bottom-l, .has-marg-bottom-lg {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-l, .has-marg-bottom-lg {
    margin-bottom: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-l, .has-marg-bottom-lg {
    margin-bottom: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-l, .has-marg-bottom-lg {
    margin-bottom: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-l, .has-marg-bottom-lg {
    margin-bottom: 3rem;
  }
}

.has-marg-bottom-xl {
  margin-bottom: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-xl {
    margin-bottom: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-xl {
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-xl {
    margin-bottom: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-xl {
    margin-bottom: 4rem;
  }
}

.has-marg-bottom-xxl {
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-xxl {
    margin-bottom: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-xxl {
    margin-bottom: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-xxl {
    margin-bottom: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-xxl {
    margin-bottom: 6rem;
  }
}

.has-marg-bottom-3xl {
  margin-bottom: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-3xl {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-3xl {
    margin-bottom: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-3xl {
    margin-bottom: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-3xl {
    margin-bottom: 8rem;
  }
}

.has-marg-bottom-4xl {
  margin-bottom: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-bottom-4xl {
    margin-bottom: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-bottom-4xl {
    margin-bottom: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-bottom-4xl {
    margin-bottom: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-bottom-4xl {
    margin-bottom: 12rem;
  }
}

.has-pad-bottom-none {
  padding-bottom: 0 !important;
}

.has-pad-bottom {
  padding-bottom: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom {
    padding-bottom: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom {
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom {
    padding-bottom: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom {
    padding-bottom: 2rem;
  }
}

.has-pad-bottom-xxs {
  padding-bottom: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-xxs {
    padding-bottom: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-xxs {
    padding-bottom: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-xxs {
    padding-bottom: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-xxs {
    padding-bottom: .5rem;
  }
}

.has-pad-bottom-xs {
  padding-bottom: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-xs {
    padding-bottom: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-xs {
    padding-bottom: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-xs {
    padding-bottom: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-xs {
    padding-bottom: 1rem;
  }
}

.has-pad-bottom-s, .has-pad-bottom-sm {
  padding-bottom: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-s, .has-pad-bottom-sm {
    padding-bottom: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-s, .has-pad-bottom-sm {
    padding-bottom: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-s, .has-pad-bottom-sm {
    padding-bottom: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-s, .has-pad-bottom-sm {
    padding-bottom: 1.5rem;
  }
}

.has-pad-bottom-m, .has-pad-bottom-md {
  padding-bottom: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-m, .has-pad-bottom-md {
    padding-bottom: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-m, .has-pad-bottom-md {
    padding-bottom: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-m, .has-pad-bottom-md {
    padding-bottom: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-m, .has-pad-bottom-md {
    padding-bottom: 2rem;
  }
}

.has-pad-bottom-l, .has-pad-bottom-lg {
  padding-bottom: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-l, .has-pad-bottom-lg {
    padding-bottom: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-l, .has-pad-bottom-lg {
    padding-bottom: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-l, .has-pad-bottom-lg {
    padding-bottom: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-l, .has-pad-bottom-lg {
    padding-bottom: 3rem;
  }
}

.has-pad-bottom-xl {
  padding-bottom: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-xl {
    padding-bottom: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-xl {
    padding-bottom: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-xl {
    padding-bottom: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-xl {
    padding-bottom: 4rem;
  }
}

.has-pad-bottom-xxl {
  padding-bottom: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-xxl {
    padding-bottom: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-xxl {
    padding-bottom: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-xxl {
    padding-bottom: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-xxl {
    padding-bottom: 6rem;
  }
}

.has-pad-bottom-3xl {
  padding-bottom: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-3xl {
    padding-bottom: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-3xl {
    padding-bottom: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-3xl {
    padding-bottom: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-3xl {
    padding-bottom: 8rem;
  }
}

.has-pad-bottom-4xl {
  padding-bottom: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-bottom-4xl {
    padding-bottom: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-bottom-4xl {
    padding-bottom: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-bottom-4xl {
    padding-bottom: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-bottom-4xl {
    padding-bottom: 12rem;
  }
}

.has-marg-left-auto {
  margin-left: auto;
}

.has-marg-left-none {
  margin-left: 0 !important;
}

.has-marg-left {
  margin-left: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left {
    margin-left: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left {
    margin-left: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left {
    margin-left: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left {
    margin-left: 2rem;
  }
}

.has-marg-left-xxs {
  margin-left: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-xxs {
    margin-left: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-xxs {
    margin-left: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-xxs {
    margin-left: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-xxs {
    margin-left: .5rem;
  }
}

.has-marg-left-xs {
  margin-left: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-xs {
    margin-left: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-xs {
    margin-left: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-xs {
    margin-left: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-xs {
    margin-left: 1rem;
  }
}

.has-marg-left-s, .has-marg-left-sm {
  margin-left: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-s, .has-marg-left-sm {
    margin-left: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-s, .has-marg-left-sm {
    margin-left: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-s, .has-marg-left-sm {
    margin-left: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-s, .has-marg-left-sm {
    margin-left: 1.5rem;
  }
}

.has-marg-left-m, .has-marg-left-md {
  margin-left: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-m, .has-marg-left-md {
    margin-left: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-m, .has-marg-left-md {
    margin-left: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-m, .has-marg-left-md {
    margin-left: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-m, .has-marg-left-md {
    margin-left: 2rem;
  }
}

.has-marg-left-l, .has-marg-left-lg {
  margin-left: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-l, .has-marg-left-lg {
    margin-left: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-l, .has-marg-left-lg {
    margin-left: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-l, .has-marg-left-lg {
    margin-left: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-l, .has-marg-left-lg {
    margin-left: 3rem;
  }
}

.has-marg-left-xl {
  margin-left: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-xl {
    margin-left: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-xl {
    margin-left: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-xl {
    margin-left: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-xl {
    margin-left: 4rem;
  }
}

.has-marg-left-xxl {
  margin-left: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-xxl {
    margin-left: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-xxl {
    margin-left: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-xxl {
    margin-left: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-xxl {
    margin-left: 6rem;
  }
}

.has-marg-left-3xl {
  margin-left: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-3xl {
    margin-left: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-3xl {
    margin-left: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-3xl {
    margin-left: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-3xl {
    margin-left: 8rem;
  }
}

.has-marg-left-4xl {
  margin-left: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-left-4xl {
    margin-left: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-left-4xl {
    margin-left: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-left-4xl {
    margin-left: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-left-4xl {
    margin-left: 12rem;
  }
}

.has-pad-left-none {
  padding-left: 0 !important;
}

.has-pad-left {
  padding-left: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left {
    padding-left: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left {
    padding-left: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left {
    padding-left: 2rem;
  }
}

.has-pad-left-xxs {
  padding-left: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-xxs {
    padding-left: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-xxs {
    padding-left: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-xxs {
    padding-left: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-xxs {
    padding-left: .5rem;
  }
}

.has-pad-left-xs {
  padding-left: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-xs {
    padding-left: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-xs {
    padding-left: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-xs {
    padding-left: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-xs {
    padding-left: 1rem;
  }
}

.has-pad-left-s, .has-pad-left-sm {
  padding-left: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-s, .has-pad-left-sm {
    padding-left: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-s, .has-pad-left-sm {
    padding-left: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-s, .has-pad-left-sm {
    padding-left: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-s, .has-pad-left-sm {
    padding-left: 1.5rem;
  }
}

.has-pad-left-m, .has-pad-left-md {
  padding-left: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-m, .has-pad-left-md {
    padding-left: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-m, .has-pad-left-md {
    padding-left: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-m, .has-pad-left-md {
    padding-left: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-m, .has-pad-left-md {
    padding-left: 2rem;
  }
}

.has-pad-left-l, .has-pad-left-lg {
  padding-left: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-l, .has-pad-left-lg {
    padding-left: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-l, .has-pad-left-lg {
    padding-left: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-l, .has-pad-left-lg {
    padding-left: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-l, .has-pad-left-lg {
    padding-left: 3rem;
  }
}

.has-pad-left-xl {
  padding-left: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-xl {
    padding-left: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-xl {
    padding-left: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-xl {
    padding-left: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-xl {
    padding-left: 4rem;
  }
}

.has-pad-left-xxl {
  padding-left: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-xxl {
    padding-left: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-xxl {
    padding-left: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-xxl {
    padding-left: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-xxl {
    padding-left: 6rem;
  }
}

.has-pad-left-3xl {
  padding-left: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-3xl {
    padding-left: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-3xl {
    padding-left: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-3xl {
    padding-left: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-3xl {
    padding-left: 8rem;
  }
}

.has-pad-left-4xl {
  padding-left: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-left-4xl {
    padding-left: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-left-4xl {
    padding-left: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-left-4xl {
    padding-left: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-left-4xl {
    padding-left: 12rem;
  }
}

.has-marg-right-auto {
  margin-right: auto;
}

.has-marg-right-none {
  margin-right: 0 !important;
}

.has-marg-right {
  margin-right: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right {
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right {
    margin-right: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right {
    margin-right: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right {
    margin-right: 2rem;
  }
}

.has-marg-right-xxs {
  margin-right: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-xxs {
    margin-right: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-xxs {
    margin-right: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-xxs {
    margin-right: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-xxs {
    margin-right: .5rem;
  }
}

.has-marg-right-xs {
  margin-right: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-xs {
    margin-right: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-xs {
    margin-right: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-xs {
    margin-right: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-xs {
    margin-right: 1rem;
  }
}

.has-marg-right-s, .has-marg-right-sm {
  margin-right: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-s, .has-marg-right-sm {
    margin-right: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-s, .has-marg-right-sm {
    margin-right: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-s, .has-marg-right-sm {
    margin-right: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-s, .has-marg-right-sm {
    margin-right: 1.5rem;
  }
}

.has-marg-right-m, .has-marg-right-md {
  margin-right: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-m, .has-marg-right-md {
    margin-right: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-m, .has-marg-right-md {
    margin-right: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-m, .has-marg-right-md {
    margin-right: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-m, .has-marg-right-md {
    margin-right: 2rem;
  }
}

.has-marg-right-l, .has-marg-right-lg {
  margin-right: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-l, .has-marg-right-lg {
    margin-right: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-l, .has-marg-right-lg {
    margin-right: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-l, .has-marg-right-lg {
    margin-right: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-l, .has-marg-right-lg {
    margin-right: 3rem;
  }
}

.has-marg-right-xl {
  margin-right: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-xl {
    margin-right: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-xl {
    margin-right: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-xl {
    margin-right: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-xl {
    margin-right: 4rem;
  }
}

.has-marg-right-xxl {
  margin-right: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-xxl {
    margin-right: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-xxl {
    margin-right: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-xxl {
    margin-right: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-xxl {
    margin-right: 6rem;
  }
}

.has-marg-right-3xl {
  margin-right: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-3xl {
    margin-right: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-3xl {
    margin-right: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-3xl {
    margin-right: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-3xl {
    margin-right: 8rem;
  }
}

.has-marg-right-4xl {
  margin-right: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-marg-right-4xl {
    margin-right: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-marg-right-4xl {
    margin-right: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-marg-right-4xl {
    margin-right: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-marg-right-4xl {
    margin-right: 12rem;
  }
}

.has-pad-right-none {
  padding-right: 0 !important;
}

.has-pad-right {
  padding-right: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right {
    padding-right: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right {
    padding-right: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right {
    padding-right: 2rem;
  }
}

.has-pad-right-xxs {
  padding-right: .25rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-xxs {
    padding-right: .3125rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-xxs {
    padding-right: .375rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-xxs {
    padding-right: .4375rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-xxs {
    padding-right: .5rem;
  }
}

.has-pad-right-xs {
  padding-right: .5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-xs {
    padding-right: .625rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-xs {
    padding-right: .75rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-xs {
    padding-right: .875rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-xs {
    padding-right: 1rem;
  }
}

.has-pad-right-s, .has-pad-right-sm {
  padding-right: .75rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-s, .has-pad-right-sm {
    padding-right: .9375rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-s, .has-pad-right-sm {
    padding-right: 1.125rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-s, .has-pad-right-sm {
    padding-right: 1.3125rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-s, .has-pad-right-sm {
    padding-right: 1.5rem;
  }
}

.has-pad-right-m, .has-pad-right-md {
  padding-right: 1rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-m, .has-pad-right-md {
    padding-right: 1.25rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-m, .has-pad-right-md {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-m, .has-pad-right-md {
    padding-right: 1.75rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-m, .has-pad-right-md {
    padding-right: 2rem;
  }
}

.has-pad-right-l, .has-pad-right-lg {
  padding-right: 1.5rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-l, .has-pad-right-lg {
    padding-right: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-l, .has-pad-right-lg {
    padding-right: 2.25rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-l, .has-pad-right-lg {
    padding-right: 2.625rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-l, .has-pad-right-lg {
    padding-right: 3rem;
  }
}

.has-pad-right-xl {
  padding-right: 2rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-xl {
    padding-right: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-xl {
    padding-right: 3rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-xl {
    padding-right: 3.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-xl {
    padding-right: 4rem;
  }
}

.has-pad-right-xxl {
  padding-right: 3rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-xxl {
    padding-right: 3.75rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-xxl {
    padding-right: 4.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-xxl {
    padding-right: 5.25rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-xxl {
    padding-right: 6rem;
  }
}

.has-pad-right-3xl {
  padding-right: 4rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-3xl {
    padding-right: 5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-3xl {
    padding-right: 6rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-3xl {
    padding-right: 7rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-3xl {
    padding-right: 8rem;
  }
}

.has-pad-right-4xl {
  padding-right: 6rem;
}

@media screen and (min-width: 768px), print {
  .has-pad-right-4xl {
    padding-right: 7.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .has-pad-right-4xl {
    padding-right: 9rem;
  }
}

@media screen and (min-width: 1200px) {
  .has-pad-right-4xl {
    padding-right: 10.5rem;
  }
}

@media screen and (min-width: 1460px) {
  .has-pad-right-4xl {
    padding-right: 12rem;
  }
}

.is-hidden {
  display: none !important;
}

.is-underline {
  text-decoration: underline;
}

.visually-hidden, .is-sr-only {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute !important;
}

a {
  color: #091f40;
}

a:hover {
  color: #091f40bf;
}

p:not(:last-child) {
  margin-bottom: 1.5em;
}

.title.is-1 {
  font-family: Graphik, sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-1 {
    font-size: 2.125rem;
  }
}

@media screen and (min-width: 768px), print {
  .title.is-1 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .title.is-1 {
    font-size: 2.25rem;
  }
}

.title.is-2 {
  font-family: Graphik, sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-2 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px), print {
  .title.is-2 {
    font-size: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .title.is-2 {
    font-size: 2rem;
  }
}

.title.is-3 {
  font-family: Graphik, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-3 {
    font-size: 1.25rem;
  }
}

.title.is-4 {
  font-family: Graphik, sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-4 {
    font-size: 1.125rem;
  }
}

.title.is-5 {
  font-family: Graphik, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-5 {
    font-size: 1rem;
  }
}

.title.is-6 {
  font-family: Graphik, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .title.is-6 {
    font-size: .875rem;
  }
}

.text.is-p1 {
  font-family: Graphik, sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.3;
}

.text.is-p2 {
  font-family: Graphik, sans-serif;
  font-size: .875rem;
  font-weight: 300;
  line-height: 1.3;
}

.nav-label {
  font-family: Graphik, sans-serif;
  font-size: .8125rem;
  font-weight: 600;
  line-height: 1.3889;
}

.text-label {
  font-family: Graphik, sans-serif;
  font-size: .875rem;
  line-height: 1.3889;
}

.small-label, .smaller-label {
  letter-spacing: .1em;
  font-family: Graphik, sans-serif;
  font-size: .75rem;
  line-height: 1.23077;
}

.smallest-label {
  letter-spacing: .1em;
  font-family: Graphik, sans-serif;
  font-size: .6875rem;
  line-height: 1.23077;
}

.lead-label {
  font-family: Graphik, sans-serif;
  font-size: 1.25rem;
  line-height: 1.23077;
}

.large-label {
  font-family: Graphik, sans-serif;
  font-size: 2rem;
  line-height: 1.23077;
}

@media screen and (max-width: 767px) {
  .large-label {
    font-size: 1.5rem;
  }
}

.sub-label {
  text-transform: uppercase;
  letter-spacing: .13em;
  font-family: Graphik, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.3;
}

.title.is-up, .text-label.is-up, .small-label.is-up, .smaller-label.is-up, .smallest-label.is-up, .lead-label.is-up, .large-label.is-up {
  text-transform: uppercase;
}

.title.is-light, .text-label.is-light, .small-label.is-light, .smaller-label.is-light, .smallest-label.is-light, .lead-label.is-light, .large-label.is-light, .title.is-normal, .text-label.is-normal, .small-label.is-normal, .smaller-label.is-normal, .smallest-label.is-normal, .lead-label.is-normal, .large-label.is-normal {
  font-weight: 400;
}

.title.is-medium, .text-label.is-medium, .small-label.is-medium, .smaller-label.is-medium, .smallest-label.is-medium, .lead-label.is-medium, .large-label.is-medium {
  font-weight: 500;
}

.title.is-strong, .text-label.is-strong, .small-label.is-strong, .smaller-label.is-strong, .smallest-label.is-strong, .lead-label.is-strong, .large-label.is-strong {
  font-weight: 600;
}

.button {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .08em;
  height: 50px;
  text-align: center;
  cursor: pointer;
  min-width: 190px;
  background: none;
  border: 1px solid #0000;
  border-radius: 5px;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 3.5em;
  font-family: Graphik, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: all .28s ease-out;
  display: inline-flex;
}

.button, .button.is-outline {
  color: #091f40;
  border-color: #091f40;
}

.button:hover, .button.is-outline:hover {
  color: #fff;
  background: #091f40;
  border-color: #091f40;
}

.button.is-yellow {
  color: #fff;
  background: #ffc600;
  border-color: #ffc600;
}

.button.is-yellow:hover {
  color: #fff;
  background: #ffc90f;
  border-color: #ffc90f;
}

.button.is-pink {
  color: #fff;
  background: #fbc3b4;
  border-color: #fbc3b4;
}

.button.is-pink:hover {
  color: #fff;
  background: #fccbbe;
  border-color: #fccbbe;
}

.button.is-light {
  color: #fff;
  border-color: #fff;
}

.button.is-light:hover {
  color: #091f40;
  background: #fff;
}

.button.is-dark {
  color: #fff;
  background: #091f40;
  border-color: #091f40;
}

.button.is-dark:hover {
  color: #fff;
  background: #0f356d;
}

.button.is-black {
  color: #fff;
  background: #010101;
  border-color: #010101;
}

.button.is-black:hover {
  color: #fff;
  background: #1b1b1b;
}

.button.is-wide {
  min-width: 15rem;
}

.button.is-narrow {
  padding-left: 2em;
  padding-right: 2em;
}

.button.is-full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.button:disabled, .button.is-disabled {
  opacity: .7;
  cursor: default;
}

.button.is-loading {
  cursor: wait;
}

.button__label + .button__icon {
  margin-right: .9375rem;
}

.button__icon + .button__label {
  margin-left: .9375rem;
}

.button-link {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-family: Graphik, sans-serif;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1.05;
  display: inline-flex;
}

.button-link.is-danger, .button-link.is-error, .button-link.is-red {
  color: #df402a;
}

.button-link.is-full {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.notification {
  text-align: center;
  color: #091f40;
  border-radius: 7px;
  margin: 1.875rem 0;
  font-family: Graphik, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.3889;
}

.notification.is-error {
  color: #df402a;
}

.notification.is-success {
  background: #fcefce;
}

.notification li + li {
  margin-top: .5em;
}

body {
  background: #ffc600;
}

.wrap {
  min-width: 320px;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .wrap {
    display: block;
    overflow: hidden;
  }
}

.main {
  z-index: 1;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.container {
  width: 100%;
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 768px), print {
  .container {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1060px) {
  .container {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1460px) {
  .container {
    padding-left: 30px;
  }
}

@media screen and (min-width: 768px), print {
  .container {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1060px) {
  .container {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1460px) {
  .container {
    padding-right: 30px;
  }
}

.content {
  font-size: 1rem;
}

.content p:not(:last-child), .content ul:not(:last-child) {
  margin-bottom: .5em;
}

.content ul {
  margin-top: 0;
}

.content h1 {
  font-family: Graphik, sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h1 {
    font-size: 2.125rem;
  }
}

@media screen and (min-width: 768px), print {
  .content h1 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1060px) {
  .content h1 {
    font-size: 2.25rem;
  }
}

.content h2 {
  font-family: Graphik, sans-serif;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h2 {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px), print {
  .content h2 {
    font-size: 1.875rem;
  }
}

@media screen and (min-width: 1060px) {
  .content h2 {
    font-size: 2rem;
  }
}

.content h3 {
  font-family: Graphik, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h3 {
    font-size: 1.25rem;
  }
}

.content h4 {
  font-family: Graphik, sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h4 {
    font-size: 1.125rem;
  }
}

.content h5 {
  font-family: Graphik, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h5 {
    font-size: 1rem;
  }
}

.content h6 {
  font-family: Graphik, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
}

@media screen and (max-width: 767px) {
  .content h6 {
    font-size: .875rem;
  }
}

.ui-form__field {
  position: relative;
}

.ui-form__field.is-textarea {
  max-width: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.ui-form__field.is-textarea textarea {
  color: #091f40;
  resize: none;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 18px 15px;
}

.ui-form__field.is-textarea textarea.has-border {
  border: 1px solid #091f40;
}

.ui-form__label {
  text-align: left;
  margin-bottom: .1875rem;
  font-family: Graphik, sans-serif;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.3889;
  display: block;
}

.ui-form__input {
  width: 100%;
  color: #091f40;
  background: #fff;
  border: 0;
  border-radius: 4px;
  outline: none;
  padding: 18px 15px;
  font-family: Graphik, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  transition: opacity .25s ease-out;
}

.ui-form__input.has-border {
  border: 1px solid #091f40;
}

.ui-form__input:disabled, .ui-form__select select:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.ui-form__textarea.has-border {
  border: 1px solid #091f40;
}

.ui-form__error {
  color: #af1a1a;
  text-align: right;
  pointer-events: none;
  margin-top: 2px;
  padding: .25rem .5rem;
  font-size: .8125rem;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.ui-form__btn {
  margin-left: 15px;
}

@media screen and (max-width: 479px) {
  .ui-form__btn {
    margin-left: 0;
  }
}

textarea {
  resize: none;
}

.header {
  position: relative;
}

.header__logo {
  z-index: 10;
  justify-content: center;
  padding-top: .625rem;
  padding-bottom: .625rem;
  display: flex;
}

.header__logo svg {
  width: 6.25rem;
  height: auto;
  display: block;
}

.footer {
  text-align: center;
  padding-top: .625rem;
  padding-bottom: 1.25rem;
}

.footer button {
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: .9375rem;
  font-weight: 500;
  text-decoration: underline;
}

.footer__terms {
  width: 100%;
  height: 100%;
  z-index: 100;
  text-align: left;
  background: #ffc600;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.footer__terms-close {
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  background: none;
  border: 0;
  outline: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.footer__terms-close svg {
  width: 2.5rem;
  height: auto;
  display: block;
}

.newsletter {
  position: relative;
}

.newsletter__error {
  color: #df402a;
  text-align: center;
  padding-top: 1em;
  font-weight: 500;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.newsletter__content {
  width: 22.5rem;
  margin-left: auto;
  margin-right: auto;
}

.newsletter__success {
  position: relative;
}

.newsletter__success-progress {
  z-index: 10;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: -7.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.newsletter__success-progress svg {
  --progress: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 5px;
  stroke: #091f40;
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

/*# sourceMappingURL=index.2de867cd.css.map */
