.ui-form {
  &__field {
    position: relative;

    &.is-textarea {
      position: relative;

      display: flex;
      flex-direction: column;
      max-width: 100%;

      & textarea {
        color: $blue-dark;
        border: none;
        outline: none;
        padding: 18px 15px;
        resize: none;
        border-radius: 4px;

        &.has-border {
          border: 1px solid $dark;
        }
      }
    }
  }

  &__label {
    @include text-label;
    font-weight: $weight-medium;
    display: block;
    text-align: left;
    margin-bottom: rem(3);
  }

  &__input {
    width: 100%;
    padding: 18px 15px;

    color: $blue-dark;
    border: 0;
    border-radius: 4px;
    outline: none;
    background: $white;

    font-family: $family-body;
    font-weight: $weight-medium;
    font-size: rem(16);

    transition: opacity $speed-fast $easing;

    &.has-border {
      border: 1px solid $dark;
    }
  }

  &__input,
  &__select select {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__textarea {
    &.has-border {
      border: 1px solid $dark;
    }
  }

  &__error {
    margin-top: 2px;

    color: $error;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    padding: rem(4) rem(8);
    pointer-events: none;

    font-size: rem(13);
    font-weight: $weight-medium;
  }

  &__btn {
    margin-left: 15px;

    @include tiny {
      margin-left: 0;
    }
  }
}

textarea {
  resize: none;
}
