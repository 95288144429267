.content {
  font-size: $size-medium;


  p,
  ul {
    &:not(:last-child) {
      margin-bottom: .5em;
    }
  }

  ul {
    margin-top: 0;
  }

  h1 {
    @include typo-heading-h(1);
  }
  h2 {
    @include typo-heading-h(2);
  }
  h3 {
    @include typo-heading-h(3);
  }
  h4 {
    @include typo-heading-h(4);
  }
  h5 {
    @include typo-heading-h(5);
  }
  h6 {
    @include typo-heading-h(6);
  }
}
