.newsletter {
  position: relative;
  &__error {
    padding-top: 1em;
    color: $danger;
    font-weight: $weight-medium;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
  }
  &__content {
    width: rem(360);
    margin-left: auto;
    margin-right: auto;
  }

  &__success {
    position: relative;
    &-progress {
      position: absolute;
      left: 50%;
      bottom: rem(-120);
      transform: translateX(-50%);
      z-index: 10;
      width: 52px;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        --progress: 0;
        position: absolute;
        left: 0;
        top: 0px;
        z-index: 10;
        width: 100%;
        height: 100%;
        stroke-width: 5px;
        stroke: $blue-dark;
        fill: none;
        stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
        stroke-dasharray: 125.6;
        transform: rotate(-90deg);
      }
    }
  }
}
