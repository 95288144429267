@mixin typo-heading-h($heading, $lineHeight: true) {
  font-family: $family-heading;
  font-weight: $weight-heading;
  @if($lineHeight) {
    line-height: $line-height-heading;
  }
  @if ($heading == 1) {
    @include mobile {
      font-size: rem(34);
    }
    @include tablet {
      font-size: rem(40);
    }
    @include desktop {
      font-size: $size-1;
    }
  } @else if ($heading == 2) {
    @include mobile {
      font-size: rem(24);
    }
    @include tablet {
      font-size: rem(30);
    }
    @include desktop {
      font-size: $size-2;
    }
  } @else if ($heading == 3) {
    font-size: $size-3;
    @include mobile {
      font-size: rem(20);
    }
  } @else if ($heading == 4) {
    font-size: $size-4;
    @include mobile {
      font-size: rem(18);
    }
  } @else if ($heading == 5) {
    font-size: $size-5;
    @include mobile {
      font-size: rem(16);
    }
  } @else if ($heading == 6) {
    font-size: $size-6;
    @include mobile {
      font-size: rem(14);
    }
  }
}
