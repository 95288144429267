.is-hidden {
  display: none !important;
}

.is-underline {
  text-decoration: underline;
}

.visually-hidden,
.is-sr-only {
  @include visually-hidden;
}

a {
  color: $link;

  &:hover {
    color: $link-hover;
  }
}

p {
  &:not(:last-child) {
    margin-bottom: 1.5em;
  }
}

.title {
  &.is-1 {
    @include typo-heading-h(1);
  }

  &.is-2 {
    @include typo-heading-h(2);
  }

  &.is-3 {
    @include typo-heading-h(3);
  }

  &.is-4 {
    @include typo-heading-h(4);
  }

  &.is-5 {
    @include typo-heading-h(5);
  }

  &.is-6 {
    @include typo-heading-h(6);
  }
}

.text {
  &.is-p1 {
    @include typo-text("p1");
  }
  &.is-p2 {
    @include typo-text("p2");
  }
}

.nav-label {
  @include nav-label;
}

.text-label {
  @include text-label;
}

.small-label {
  @include small-label;
}

.smaller-label {
  @include smaller-label;
}

.smallest-label {
  @include smallest-label;
}

.lead-label {
  @include lead-label;
}

.large-label {
  @include large-label;
}

.sub-label {
  @include typo-text("p2");
  text-transform: uppercase;
  font-weight: $weight-medium;
  letter-spacing: 0.13em;
}

.title,
.text-label,
.small-label,
.smaller-label,
.smallest-label,
.lead-label,
.large-label {
  &.is-up {
    text-transform: uppercase;
  }
  &.is-light {
    font-weight: $weight-normal;
  }
  &.is-normal {
    font-weight: $weight-normal;
  }
  &.is-medium {
    font-weight: $weight-medium;
  }
  &.is-strong {
    font-weight: $weight-semibold;
  }
}
