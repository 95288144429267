.notification {
  margin: rem(30) 0;
  @include text-label;
  font-weight: $weight-medium;
  text-align: center;
  color: $text-dark;
  border-radius: $radius;
  &.is-error {
    color: $red;
  }

  &.is-success {
    background: $cream-dark;
  }

  li + li {
    margin-top: .5em;
  }
}
