@mixin visually-hidden() {
  // sass-lint:disable no-important
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown() {
  // sass-lint:disable no-important
  position: inherit !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}
