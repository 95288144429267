$family-sans-serif: Graphik, sans-serif;
$family-body: $family-sans-serif;
$family-heading: $family-sans-serif;

$size-1: 2.25rem;
$size-2: 2rem;
$size-3: 1.5rem;
$size-4: 1.375rem;
$size-5: 1.125rem;
$size-6: 1rem;
$size-7: 0.875rem;
$size-8: 0.8125rem;
$size-9: 0.75rem;
$size-10: 0.6875rem;

$size-smallest: $size-10;
$size-smaller: $size-9;
$size-small: $size-8;
$size-normal: $size-7;
$size-medium: $size-6;
$size-large: $size-4;
$size-larger: $size-3;
$size-largest: $size-2;

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8 $size-9 $size-10;

// IMPORTANT keep it in pixels
$body-size: 16px;
$body-font-size: $size-normal;
$body-weight: 300;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$weight-heading: $weight-semibold;

$line-height-fixed: 1;
$line-height-small: 1.1;
$line-height-label: 1.230769231;
$line-height-heading: $line-height-small;
$line-height-text: 1.3889;

$strong-weight: $weight-bold;
