@mixin transition-generic($p: all, $e: $easing, $s: $speed) {
  transition: $p $e $s;
}

@mixin transition-generic-slow($p: all, $e: $easing, $s: $speed-slow) {
  @include transition-generic($p, $e, $s);
}

@mixin transition-generic-slower($p: all, $e: $easing, $s: $speed-slower) {
  @include transition-generic($p, $e, $s);
}

@mixin transition-link {
  @include transition-generic-slow;
}

@mixin transition-button {
  @include transition-generic-slow;
}

@mixin transition-sliding {
  @include transition-generic(transform, $speed-slow, $easing);
}
