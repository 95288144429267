@charset "utf-8";

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  src: url('assets/graphik-regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  src: url('assets/graphik-medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  src: url('assets/graphik-semibold.woff2') format('woff2');
}

@import "styles/settings/_index";
@import "node_modules/bulma/sass/utilities/_all";
@import "node_modules/bulma/sass/base/_all";
@import "node_modules/bulma/sass/grid/_all";
@import "node_modules/bulma/sass/helpers/typography";
@import "node_modules/bulma/sass/elements/content";

@import "styles/utils/_index";
@import "styles/base/_index";
@import "styles/components/_index";
