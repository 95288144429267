@mixin typo-text($variant, $lineHeight: true) {
    font-family: $family-body;
    font-weight: $weight-normal;

    @if ($variant == "p1") {
      font-size: rem(18);
      font-weight: $weight-light;
      line-height: 1.3;
    } @else if ($variant == "p2") {
      font-size: rem(14);
      font-weight: $weight-light;
      line-height: 1.3;
    } @else if ($variant == "p3") {
      font-size: rem(13);
      line-height: 1.3;
    }
  }
  