@use "sass:math";

$body-overflow-x: initial;
$body-overflow-y: initial;

$sides: (top, bottom, left, right);
$spacing: 1rem;
$spacing-xxs: $spacing * .25;
$spacing-xs: $spacing * .5;
$spacing-s: $spacing * .75;
$spacing-m: $spacing;
$spacing-l: $spacing * 1.5;
$spacing-xl: $spacing * 2;
$spacing-xxl: $spacing * 3;
$spacing-3xl: $spacing * 4;
$spacing-4xl: $spacing * 6;

// The container horizontal gap, which acts as the offset for breakpoint
$gap: 36px;

// The container horizontal gap, which acts as the offset for breakpoint
$spacing-gap: 30px;
$spacing-gap-mobile: math.div($spacing-gap, 2);
$spacing-gap-tablet: $spacing-gap;
$spacing-gap-desktop: $spacing-gap;
$spacing-gap-widescreen: $spacing-gap;
$spacing-gap-fullhd: $spacing-gap;

$mobile-legacy: 359px;
$mobile-legacy-max-width: $mobile-legacy + 1px;

$mobile: 479px;
$mobile-max-width: $mobile + 1px;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 768px;

// 1104px container + 4rem
$desktop: 1000px + (2 * $spacing-gap-desktop); // 1104

$widescreen: 1140px + (2 * $spacing-gap-widescreen);
$widescreen-enabled: true;

$fullhd: 1400px + (2 * $spacing-gap-fullhd);
$fullhd-enabled: true;

$variable-columns: true;

$body-overflow-x: initial;
$body-overflow-y: initial;

// columns

$column-gap: 0.625rem; // 10px

$header-height-scrolled: 48px;
$header-height-mobile: 60px;
$header-height-tablet: 90px;
$header-height-desktop: 90px;

$z-index: (
  menu-mobile-close: 10050,
  menu-mobile-level: 10010,
  menu-mobile-additional: 10007,
  menu-mobile: 10005,
  popup: 10003,
  popupBg: 10002,
  minicart: 10001,
  minicartBg: 10000,
  giftreggie: 5000,
  top-menu: 1900,
  menu-toggle: 1500,
  header: 1050,
  layer: 50,
  above: 10,
  bg: 1,
  below: -1,
);
