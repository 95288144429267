@mixin button-reset {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

@mixin button-default {
  font-family: $button-font-family;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-style: normal;
  text-decoration: none;
  height: $button-height;
  border: $button-border-width solid transparent;
  padding-left: 3.5em;
  padding-right: 3.5em;
  text-align: center;
  justify-content: center;
  line-height: $line-height-fixed;
  display: inline-flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  border-radius: $button-border-radius;
  //@include transition-generic;
}

@mixin button-hover {
  &:not(.no-hover):not(.is-loading):hover,
  .has-button:hover &,
  &.is-hovered {
    @content;
  }
}
