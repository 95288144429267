.header {
  position: relative;

  &__logo {
    z-index: 10;
    padding-top: rem(10);
    padding-bottom: rem(10);
    display: flex;
    justify-content: center;
    svg {
      display: block;
      width: rem(100);
      height: auto;
    }
  }
}
