.footer {
  padding-top: rem(10);
  padding-bottom: rem(20);

  text-align: center;

  button {
    @include button-reset;
    font-weight: $weight-medium;
    font-size: rem(15);
    text-decoration: underline;
  }

  &__terms {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: $yellow;
    text-align: left;
    display: flex;
    align-items: center;

    &-close {
      @include button-reset;
      position: absolute;
      right: 0;
      top: 0;
      width: rem(80);
      height: rem(80);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: block;
        width: rem(40);
        height: auto;
      }
    }

    &-inner {

    }
  }
}
